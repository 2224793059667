import { Locale } from "./locales"

export const frontendMessages = {
  [Locale.ENGLISH]: {
    nav_portfolio: "Portfolio",
    nav_projects: "Projects",
    nav_sketches: "Sketches",
    nav_about: "About",
    nav_contact: "Contact",
    nav_more: "More",
    nav_journal: "Art Journal",
    nav_storytelling: "Storytelling",
    nav_shop: "Shop",
    nav_subscribe: "Subscribe",
    nav_language_en: "English",
    nav_language_zh: "Chinese (Simplified)",
    hero_title: "Jun Qi",
    hero_caption: "storytelling and illustration",
    browse_portfolio: "Browse Portfolio",
    latest_work: "Latest Works",
    latest_project: "Latest Projects",
    all_in_collection: "See all",
    see_more: "See more",
    about_title: "About",
    about_description: `
      <p>I'm a quirky illustrator-author-storyteller-artist soul in recovery and a "thought-provoking jade curio" (the literal meaning of my name, Jun Qi 隽琦!). In my past life, I was a senior software developer and tech lead at a multinational software consultancy.</p>

      <p>After taking some time off to explore my desultory interests and rediscover my creative spark, I decided to dive into the pursuit of my creative work, which currently spans illustration, writing and storytelling, and will hopefully continue to grow and meander around the confluence and boundaries of all three.</p>

      <p>Illustration-wise, I am mainly a digital artist, with Krita - a FLOSS (Free/Libre Open Source Software) digital painting software - being my go-to tool, but I keep in touch with my traditional side through a daily drawing practice. As a writer and storyteller, I craft stories in both English (my first language) and Mandarin Chinese (which I speak fluently). My stories often feature my multicultural Southeast Asian heritage, elements of my Chinese Singaporean identity, whimsical environments and zany animal characters (geeky nature lover here!)</p>

      <p>Among other things, I also enjoy singing with my uke, composting, growing plants, and studying foreign languages (I can hold a basic conversation in Thai and hobble through a Sanskrit verse with plenty of help from the textbook, dictionary, and my study buddies).</p>

      <p>Thanks for dropping by, and if you'd like to work with me, you can shoot me an email at <b>hello@tanjunqi.art</b> 😸</p>
      `,
    get_in_touch_title: "Get In Touch",
    get_in_touch_description: `
      <p> If you're interested in working with me, drop me an email at
      <b>hello@tanjunqi.art</b> and let's talk! You can also <a>subscribe</a> to my newsletter to get updates on my work.</p>
    `,
    awards_title: "Awards",
    storytelling_title: "Storytelling",
    education_title: "Education",
    shop_title: "Redbubble Shop",
    explore_title: "Explore",
    explore_section_portfolio_name: "Portfolio",
    explore_section_projects_name: "Projects",
    explore_section_journal_name: "Art Journal",
    explore_section_storytelling_name: "Storytelling Podcast",
    explore_section_shop_name: "Shop",
    explore_section_subscribe_name: "Subscribe",
    footer_all_rights_reserved: "All rights reserved",
    footer_privacy_policy: "Privacy Policy",
    footer_terms_and_conditions: "Terms and Conditions",
    portfolio_title: "Portfolio",
    portfolio_subtitle: "Selected illustrations and artworks",
    art_journal_title: "Art Journal",
    art_journal_subtitle: "A chronological record of my artworks and sketches",
    category_all: "All",
    category_illustration: "Illustration",
    category_childrens_books: "Children's Books",
    category_comics: "Comics",
    category_character_design: "Character Design",
    category_portraits: "Portraits",
    category_studies: "Studies",
    category_decorative: "Decorative",
    category_papercut: "Papercuts",
    category_animation: "Animation",
    category_storytelling: "Storytelling Props",
    category_poster_design: "Poster Design",
    category_cover_design: "Cover Design",
    category_sketches: "Sketches",
    projects_title: "Projects",
    projects_subtitle: "Various completed and ongoing creative projects",
    project_category_books: "Books",
    project_category_art_and_illustration: "Art & Illustration",
    project_category_comics: "Comics",
    ongoing_status: "Ongoing",
    project_artworks: "Artworks",
    sketches_title: "Sketches",
    sketches_subtitle: "Regular sketching and traditional media practice",
    not_found_heading: "Oops, are you lost?",
    not_found_description: "You probably want to head <a>home!</a>",
    subscribe_heading: "Subscribe to my mailing list",
    subscribe_thanks: "Thanks for subscribing!",
    subscribe_description: `
    <p>
      Get quality storytelling and illustration content that children will love straight to your inbox.
      Choose the content you're interested in, with only four emails a month, max.
    </p>
    <p>No spam, that's a promise.</p>
    `,
    subscribe_disclaimer: `
      <p>
        The information you provide in this form is collected solely for the purposes of sending you content specified above.
        Your information will never be shared with anyone else, aside from the third-party service (ie. SendGrid) used for mailing list integration and email delivery.
        You may unsubscribe at any time using the Unsubscribe link in the email footer.
      </p>
      <p>For more details, you may refer to the site's <a>privacy policy</a>.</p>
    `,
    subscription_form_email: "Email*",
    subscription_form_given_name: "Given name*",
    subscription_form_content_type_legend:
      "What type of content are you interested in?",
    subscription_form_content_storytelling: "Storytelling",
    subscription_form_content_storytelling_details: `
      <li>Monthly storytelling podcast</li>
      <li>Updates about upcoming storytelling events</li>
      <li>Activity guides for children</li>
    `,
    subscription_form_content_storytelling_language_legend:
      "In the following languages:",
    subscription_form_content_storytelling_language_english_only:
      "English-only",
    subscription_form_content_storytelling_language_english_mandarin:
      "English and Mandarin (Bilingual)",
    subscription_form_content_storytelling_age_groups_legend:
      "For the following age groups:",
    subscription_form_content_storytelling_age_groups_0_to_2: "0 - 2 years old",
    subscription_form_content_storytelling_age_groups_3_to_5: "3 - 5 years old",
    subscription_form_content_storytelling_age_groups_6_to_9: "6 - 9 years old",
    subscription_form_content_storytelling_age_groups_10_to_12:
      "10 - 12 years old",
    subscription_form_content_illustration: "Art and illustration",
    subscription_form_content_illustration_details: `
                <li>
                  Sneak peaks into what's happening in the art studio
                </li>
                <li>Art tutorials for both children and adults</li>
    `,
    subscription_form_captcha_error_msg:
      "Oops! Something seems to have gone wrong with the captcha challenge. Please try again 🙇‍♀️",
    subscribe_button: "Subscribe",
    // Podcasts
    podcast_all_episodes: "All Episodes",
    podcast_latest_episodes_first: "Latest Episodes First",
    podcast_oldest_episodes_first: "Oldest Episodes First",
    license: "License:",
    cc_by_nc_sa_license_link:
      "https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode",
    cc_by_nc_sa_summary_link:
      "https://creativecommons.org/licenses/by-nc-sa/4.0/deed.en",
    cc_by_nc_sa_license_summary: `
      <a>Summary:</a> You are free to share and adapt this creative work under the following terms:
      you must give appropriate credit, provide a link to the license, and
      indicate if changes were made; you may not use this material for any
      commercial purposes; if you remix, transform, or build upon this material,
      you must distribute your contributions under the same license as the
      original.
    `,
    books_title: "Books",
    books_read: "Read",
    book_illustrator_and_author: "Illustrator and Author:",
    book_jun_qi: "Jun Qi",
    book_translator: "Translator:",
    book_lang_en: "English",
    book_lang_zh: "Mandarin",
    book_lang_ms: "Malay",
    book_lang_vi: "Vietnamese",
    book_lang_ta: "Tamil",
    book_lang_hi: "Hindi",
    seo_books_title: "Books",
    seo_books_description: "Books by Jun Qi",
    seo_portfolio_title: "Portfolio",
    seo_portfolio_description: "Jun Qi's illustration portfolio.",
    seo_projects_title: "Projects",
    seo_projects_description: "Jun Qi's creative projects",
    seo_art_journal_title: "Art Journal",
    seo_art_journal_description:
      "Chronological record of Jun Qi's artworks and sketches",
    seo_sketches_title: "Sketches",
    seo_sketches_description: "Jun Qi's sketches",
    seo_subscribe_title: "Subscribe",
    seo_subscribe_description:
      "Subscribe to my newsletter to get storytelling and illustration content that children will love",
  },
  [Locale.CHINESE]: {
    nav_portfolio: "作品",
    nav_projects: "项目",
    nav_sketches: "素描",
    nav_shop: "商店",
    nav_about: "关于",
    nav_contact: "联系",
    nav_more: "更多",
    nav_journal: "画画日记",
    nav_storytelling: "讲故事",
    nav_subscribe: "订阅",
    nav_language_en: "英文",
    nav_language_zh: "中文（简体）",
    hero_title: "隽琦",
    hero_caption: "讲故事、插画",
    browse_portfolio: "浏览作品",
    latest_work: "最新作品",
    latest_project: "最新项目",
    all_in_collection: "查看全部",
    see_more: "查看更多",
    about_title: "关于",
    about_description: `
      <p>
      我原本是在一家国际软件咨询公司做高级软件开发师及技术领导，但从小就很爱画画和故事创作。
花了一段时间休息，探索我多样的兴趣，和找回我的创意火花之后，我便决定把时间和精力投入在画画和创作上，成为专业插画家。
除了插画这种通过视觉的故事叙述方式之外，我也在探索相辅相成的讲故事艺术，并加入了新加坡讲故事协会，也在参与国家图书馆的讲故事义工活动。
我喜欢的东西包括FLOSS （自由/开源软件)，不被大技术公司掌控的非主流技术平台，堆肥，种菜，恢复土壤，和读书。谢谢参观！
      </p>
      `,
    get_in_touch_title: "与我合作",
    get_in_touch_description: `
    <p>
      若感兴趣与我合作，欢迎发个电邮给 <b>hello@tanjunqi.art</b>，商量合作事宜。
    </p>
    `,
    awards_title: "所获奖项",
    storytelling_title: "讲故事",
    education_title: "教育经历",
    shop_title: "Redbubble商店",
    explore_title: "探索",
    explore_section_portfolio_name: "作品集",
    explore_section_projects_name: "项目",
    explore_section_journal_name: "画画日记",
    explore_section_storytelling_name: "讲故事播客",
    explore_section_shop_name: "商店",
    explore_section_subscribe_name: "订阅",
    footer_all_rights_reserved: "版权所有",
    footer_privacy_policy: "隐私政策",
    footer_terms_and_conditions: "条款和条件",
    art_journal_title: "画画日记",
    art_journal_subtitle: "我的画画作品和素描的序时记录",
    portfolio_title: "作品集",
    portfolio_subtitle: "精选插画作品",
    category_all: "全部",
    category_illustration: "插画",
    category_childrens_books: "儿童绘本",
    category_comics: "漫画",
    category_character_design: "角色设计",
    category_portraits: "肖像",
    category_studies: "习画",
    category_decorative: "装饰",
    category_papercut: "剪纸",
    category_animation: "动画",
    category_storytelling: "讲故事道具",
    category_poster_design: "海报设计",
    category_cover_design: "封面设计",
    category_sketches: "素描",
    projects_title: "项目",
    projects_subtitle: "已完成和进行中的艺术项目",
    project_category_books: "绘本",
    project_category_art_and_illustration: "插画",
    project_category_comics: "漫画",
    ongoing_status: "进行中",
    project_artworks: "项目作品",
    sketches_title: "素描",
    sketches_subtitle: "日常素描练习作品",
    not_found_heading: "哎呀，迷路了？",
    not_found_description: "回到<a>主页</a>去吧!",
    subscribe_heading: "订阅邮件列表",
    subscribe_thanks: "谢谢订阅哦！",
    subscribe_description: `
    <p>
      孩子们喜爱的优质讲故事和画画内容送到您的邮箱。
      感兴趣的内容完全由您选择， 每个月最多四封电邮。
    </p>
    <p>不发垃圾电邮，我保证！</p>
    `,
    subscribe_disclaimer: `
      <p>
        您在这表格内所提供的信息仅用来给您发送您表示兴趣的内容。
        除了第三方邮件列表和电邮发送服务提供者（即SendGrid）以外，您的信息不会与任何其他人分享。
        您能随时通过电邮页脚里的“取消订阅”链接来取消您的订阅。
      </p>
      <p>若想查看更多，请见网站的<a>隐私政策</a>.</p>
    `,
    subscription_form_email: "电邮地址*",
    subscription_form_given_name: "名字*",
    subscription_form_content_type_legend: "您对什么样的内容感兴趣?",
    subscription_form_content_storytelling: "讲故事",
    subscription_form_content_storytelling_details: `
      <li>每月一次的讲故事播客</li>
      <li>讲故事活动预告</li>
      <li>与讲故事内容相关的小孩子活动方案</li>
    `,
    subscription_form_content_storytelling_language_legend: "用以下的语言:",
    subscription_form_content_storytelling_language_english_only: "纯英文",
    subscription_form_content_storytelling_language_english_mandarin:
      "英文和华文(双语言)",
    subscription_form_content_storytelling_age_groups_legend:
      "针对以下的年龄段:",
    subscription_form_content_storytelling_age_groups_0_to_2: "0 - 2 岁",
    subscription_form_content_storytelling_age_groups_3_to_5: "3 - 5 岁",
    subscription_form_content_storytelling_age_groups_6_to_9: "6 - 9 岁",
    subscription_form_content_storytelling_age_groups_10_to_12: "10 - 12 岁",
    subscription_form_content_illustration: "画画",
    subscription_form_content_illustration_details: `
                <li>
                  画室最新动态
                </li>
                <li>给孩子及成人的画画教程</li>
    `,
    subscription_form_captcha_error_msg:
      "哎呀！验证码挑战好像出了差错。请您再试一次 🙇‍♀️",

    subscribe_button: "订阅",
    // Podcasts
    podcast_all_episodes: "全集列表",
    podcast_latest_episodes_first: "最新到最旧",
    podcast_oldest_episodes_first: "最旧到最新",
    license: "许可协议：",
    cc_by_nc_sa_license_link:
      "https://creativecommons.org/licenses/by-nc-sa/4.0/legalcode.zh-Hans",
    cc_by_nc_sa_summary_link:
      "https://creativecommons.org/licenses/by-nc-sa/4.0/deed.zh",
    cc_by_nc_sa_license_summary: `
    <a>概要：</a> 您可以自由地共享及演绎本作品，惟须遵守下列条件：您必须给出适当的署名，提供指向本许可协议的链接，
    同时标明是否（对原始作品）作了修改；您不得将本作品用于商业目的；如果您再混合、转换或者基于本作品进行创作，
    您必须基于与原先许可协议相同的许可协议 分发您贡献的作品。
    `,
    books_title: "绘本",
    books_read: "阅读",
    book_illustrator_and_author: "图与文：",
    book_jun_qi: "隽琦",
    book_translator: "译者：",
    book_lang_en: "英文",
    book_lang_zh: "华文",
    book_lang_ms: "马来文",
    book_lang_vi: "越南文",
    book_lang_ta: "泰米尔文",
    book_lang_hi: "印度文",
    seo_books_title: "绘本",
    seo_books_description: "隽琦的绘本创作",
    seo_art_journal_title: "画画日记",
    seo_art_journal_description: "隽琦的画画日记",
    seo_portfolio_title: "作品集",
    seo_portfolio_description: "隽琦的插画作品集",
    seo_projects_title: "项目",
    seo_projects_description: "隽琦的艺术创作项目",
    seo_sketches_title: "素描",
    seo_sketches_description: "隽琦的素描画画",
    seo_subscribe_title: "订阅",
    seo_subscribe_description:
      "订阅隽琦的邮件列表， 把孩子们喜爱的优质讲故事和画画内容送到您的邮箱。",
  },
}
